<template>
  <section>
    <Toast />
    <div class="w-full">
      <div class="w-full flex justify-end">
        <Button icon="pi pi-upload" @click="displayModal = true" label="Adjuntar soportes" />
      </div>
      <Dialog :closable="false" v-model:visible="displayModal" modal header="Adjuntar soportes" :style="{ width: '40vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="w-full mb-4">
          <label for="documento" class="text-xs text-gray-600 italic">Nombre del documento</label>
          <InputText type="text" class="w-full" v-model="soporte.Nombre" />
        </div>
        <div>
          <div class="" v-if="soporte.nombre_adjunto">
            <div class="bg-blue-300 flex justify-between rounded-md p-2 text-blue-600 font-bold text-sm border border-r-2">
              <div class="flex gap-2">
                <p>{{ soporte.nombre_adjunto }}</p>
              </div>
              <button @click="eliminarArchivo">
                <i class="pi pi-times text-sm"></i>
              </button>
            </div>
          </div>
          <FileUpload
            v-else
            mode="basic"
            class="w-full bg-white text-blue-600"
            name="demo[]"
            url="./upload.php"
            accept=".pdf"
            :maxFileSize="1000000"
            chooseLabel="Adjuntar"
            @change="leerArchivo()"
            ref="file"
            id="documento"
          />
        </div>
        <template #footer>
          <Button  label="Cancelar" severity="secondary" icon="pi pi-times" />
          <Button @click="guardarArchivo" label="Guardar" icon="pi pi-save" />
        </template>
      </Dialog>
      <div class="w-full mt-4" v-if="contratosStore._soportes.length">
        <DataTable class="p-datatable-sm text-sm" showGridlines :value="contratosStore._soportes" tableStyle="min-width: 50rem">
          <Column field="Nombre" header="Nombre"></Column>
          <Column field="nombre_adjunto" header="Archivo"></Column>
          <Column field="" class="w-4 " header="Acciones">
            <template #body="slotProps">
              <div class="text-center">
                <button @click="eliminar(slotProps.data)"><i class="pi pi-trash text-red-600"></i></button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </section>
</template>
<script>
  import { ref } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useContratosStore } from '../../../stores/contratos.store'
  export default {
    name: 'Soportes',
    setup () {
      const toast = useToast()
      const contratosStore = useContratosStore()
      const soporte = ref({
        Nombre: '',
        nombre_adjunto: '',
        Url: ''
      })
      const file = ref()
      const displayModal = ref(false)
      const leerArchivo = async () => {
        const archivo = file.value.files[0]
        const reader = new FileReader()
        const blob = await fetch(archivo.objectURL).then(r => r.blob())
        soporte.value.nombre_adjunto = archivo.name
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
            const base64data = reader.result
            soporte.value.Url = base64data
        }
      }
      const guardarArchivo = () => {
        for (const [key, value] of Object.entries(soporte.value)) {
          if (!value) return toast.add({ severity: 'error', summary: 'Error', detail: `Debe ingresar todos los campos, faltante: ${key}`, life: 5000 })
        }
        contratosStore.soportes.push(soporte.value)
        cerrarModal()
      }
      const cerrarModal = () => {
        soporte.value = {
          Nombre: '',
          nombre_adjunto: '',
          Url: ''
        }
        displayModal.value = false
        file.value = ''
      }
      const eliminar = (soporte) => {
        contratosStore.soportes = contratosStore.soportes.filter(a => a !== soporte)
      }
      const eliminarArchivo = () => {
        soporte.value.nombre_adjunto = ''
        soporte.value.Url = ''
      }
      return {
        displayModal,
        soporte,
        file,
        leerArchivo,
        guardarArchivo,
        cerrarModal,
        contratosStore,
        eliminar,
        eliminarArchivo
      }
    }
  }
</script>
